import React from 'react';
import { graphql } from 'gatsby';

export default function FilterPage({ data, pageContext }) {
  return <></>;
}

// querry all vignett for each rea

export const query = graphql`
  query($categorie: String) {
    navInfo: allSanityInfos {
      nodes {
        categories
      }
    }

    navLabo: allSanityLaboratory {
      nodes {
        categories
      }
    }

    navStudio: allSanityStudio {
      nodes {
        categories
      }
    }

    queryLaboratory: allSanityLaboratory(
      filter: { categories: { eq: $categorie } }
    ) {
      nodes {
        categories
        _updatedAt
        project
        date
        slug {
          current
        }
        imageshomepage {
          alt
          asset {
            fluid(maxWidth: 1500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    queryStudio: allSanityStudio(filter: { categories: { eq: $categorie } }) {
      nodes {
        categories
        _updatedAt
        project
        date
        slug {
          current
        }
        imageshomepage {
          alt
          asset {
            fluid(maxWidth: 1500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
